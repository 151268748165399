import { TSteps } from "./ExperienceBuilderEG.types";
import Confirmation from "./components/Confirmation";
import Courses from "./components/Courses";
import Hotels from "./components/Hotels";
import Tours from "./components/Tours";
import Transport from "./components/Transport";
import TripDetails from "./components/TripDetails";
import YourDetails from "./components/YourDetails";

const ComponentMap: Record<TSteps, React.FC> = {
  confirmation: Confirmation,
  courses: Courses,
  hotels: Hotels,
  tours: Tours,
  transport: Transport,
  yourDetails: YourDetails,
  tripDetails: TripDetails,
};

export const renderComponentBasedOnActiveKey = (activeStep: TSteps) => {
  const Component = ComponentMap[activeStep];

  return Component ? <Component /> : <></>;
};
