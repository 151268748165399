import { TExperienceBuilderTour } from "components/ExperienceBuilderEG/ExperienceBuilderEG.types";
import { useExperienceBuilderContext } from "components/ExperienceBuilderEG/context/ExperienceBuilderContext";
import { useAPI } from "hooks";
import React, { useMemo, useState } from "react";
import { Button, Loader } from "shared";
import * as R from "ramda";
import TourItem from "./TourItem";
import { TToursDetails } from "./Tours";

function ToursList() {
  const {
    labels,
    domain,
    filtersSlice,
    setSelectedTours,
    selectedTours,
    setToursDetails,
    toursDetails,
    setActiveStep,
  } = useExperienceBuilderContext();

  const [formValues, setFormValues] = useState<TToursDetails>(toursDetails);

  const payload = useMemo(() => {
    if (filtersSlice.selectedRegions.length) {
      return filtersSlice.selectedRegions.map((region) => region.Id);
    }
    if (
      !filtersSlice.selectedRegions.length &&
      filtersSlice.selectedCountries.length
    ) {
      return R.uniq(
        filtersSlice.selectedCountries
          .flatMap((country) => country.Regions)
          .map((region) => region.Id)
      );
    }

    return [];
  }, [filtersSlice.selectedRegions, filtersSlice.selectedCountries]);

  const { data: tours, isLoading: isToursLoading } = useAPI<{
    Tours: TExperienceBuilderTour[];
  }>({
    url: domain + labels.Tours.ApiRoute,
    method: "POST",
    payload,
  });

  const toursList = useMemo(
    () =>
      R.groupBy(
        (tour: TExperienceBuilderTour) => tour.LocationString,
        tours?.Tours ?? []
      ),
    [tours?.Tours]
  );

  const handleTourSelection = (
    value: boolean,
    tourItem: TExperienceBuilderTour
  ) => {
    if (value) {
      setSelectedTours([...selectedTours, tourItem]);
    } else {
      setSelectedTours(selectedTours.filter((tour) => tour.Id !== tourItem.Id));
    }
  };

  return (
    <>
      {isToursLoading ? (
        <Loader />
      ) : (
        <div>
          {Object.keys(toursList).length === 0 ? (
            <div className="o-tailored-experience__no-results">
              <div />
              <span>{labels.Tours.NoResults}</span>
            </div>
          ) : (
            <>
              {Object.keys(toursList).map((locationStringKey) => (
                <React.Fragment key={locationStringKey}>
                  <div className="m-course-list__section">
                    <span>{locationStringKey}</span>
                    <span>{`${toursList[locationStringKey]?.length} ${labels.Tours.ToursText}`}</span>
                  </div>
                  <div className="m-course-list">
                    {toursList[locationStringKey]?.map((tour) => (
                      <TourItem
                        key={tour.Title}
                        tour={tour}
                        isSelected={Boolean(
                          selectedTours.find(
                            (selectedTour) => tour.Id === selectedTour.Id
                          )
                        )}
                        onChange={(value) => handleTourSelection(value, tour)}
                      />
                    ))}
                  </div>
                </React.Fragment>
              ))}
            </>
          )}
        </div>
      )}
      <div className="m-form__group">
        <label className="m-form__label-text" htmlFor="additionalInfo">
          {labels.Tours.AdditionalRequirements}
        </label>
        <textarea
          id="additionalInfo"
          onChange={(e) =>
            setFormValues(() => ({
              additionalRequirements: e.target.value,
            }))
          }
          value={formValues.additionalRequirements}
          placeholder={labels.Tours.AdditionalRequirementsPlaceholder}
          className="m-form__textarea"
        />
      </div>
      <div className="m-courses__footer">
        <Button
          disabled={isToursLoading}
          text="Next"
          onClick={() => {
            setToursDetails(formValues);
            setActiveStep("transport");
          }}
          endIcon={<i className="zmdi zmdi-long-arrow-right"></i>}
        />
        <span>
          {selectedTours.length >= 1 && <i className="zmdi zmdi-check"></i>}
          &nbsp; &nbsp;
          {`${selectedTours.length} ${labels.Tours.ToursSelected}`}
        </span>
      </div>
      <hr
        style={{ marginTop: "20px", marginBottom: "40px" }}
        className="u-divider u-divider--mobile-full-width hide-from-tablet"
      ></hr>
    </>
  );
}

export default ToursList;
