import { useEffect, useMemo, useState } from "react";

function useMedia() {
	const [screenSize, setScreenSize] = useState<number>(window.innerWidth);

	useEffect(() => {
		const handleResize = () => {
			setScreenSize(window.innerWidth);
		};

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return useMemo(
		() => ({
			isMobile: screenSize <= 768,
			isTablet: screenSize <= 1201,
			isDesktop: screenSize > 768,
		}),
		[screenSize]
	);
}

export default useMedia;
