type TImageWithTextProps = {
  text: string;
  icon: string;
  imageSrc: string;
};

function ImageWithText({ text, icon, imageSrc }: TImageWithTextProps) {
  return (
    <div className="m-image-with-text">
      <img src={imageSrc} alt={text} />
      <div className="m-image-with-text__pill m-image-with-text__pill--over-img">
        <span>{text}</span>
        <span dangerouslySetInnerHTML={{ __html: icon }}></span>
      </div>
    </div>
  );
}

export default ImageWithText;
