import { ReactNode } from "react";

type TCheckboxProps = {
  id: string;
  name: string;
  checked: boolean;
  onChange: (value: boolean) => void;
  label?: string | ReactNode;
  disabled?: boolean;
};

function Checkbox({
  id,
  name,
  checked,
  onChange,
  label,
  disabled,
}: TCheckboxProps) {
  return (
    <label className={`m-form__checkbox ${checked ? "is-checked" : ""}`}>
      <input
        className="m-form__checkbox-input"
        id={id}
        name={name}
        type="checkbox"
        checked={checked}
        onChange={() => onChange(!checked)}
        disabled={disabled}
        style={{ cursor: disabled ? "initial" : "pointer" }}
      />
      <label htmlFor={id} className="m-form__checkbox-label"></label>
      <span>{label}</span>
    </label>
  );
}

export default Checkbox;
