import { useCallback, useEffect, useMemo, useState } from "react";
import axios from "axios";

type TUseApiProps = {
  url: string;
  method: "GET" | "POST";
  payload?: any;
  isEnabled?: boolean;
};

function useAPI<T>({ url, method, payload, isEnabled = true }: TUseApiProps) {
  const [data, setData] = useState<T>();
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      setError(null);
      const response = await axios.request<T>({
        data: payload,
        method,
        url,
      });

      setData(response?.data);
    } catch (error: any) {
      const errorMessage = error?.response?.data || error?.message;
      console.error(errorMessage);
      setError(errorMessage);
    } finally {
      setIsLoading(false);
    }
  }, [payload, method, url]);

  useEffect(() => {
    if (isEnabled) {
      fetchData();
    }
  }, [fetchData, isEnabled]);

  const refetch = useCallback(() => {
    fetchData();
  }, [fetchData]);

  return useMemo(
    () => ({ data, error, isLoading, refetch }),
    [data, error, isLoading, refetch]
  );
}

export default useAPI;
