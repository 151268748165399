import {
  TExperienceBuilderLabels,
  TSteps,
} from "components/ExperienceBuilderEG/ExperienceBuilderEG.types";
import { useExperienceBuilderContext } from "components/ExperienceBuilderEG/context/ExperienceBuilderContext";
import { Button } from "shared";

const buttonKeys: TSteps[] = [
  "tripDetails",
  "courses",
  "hotels",
  "tours",
  "transport",
  "yourDetails",
];

const getStepTitle = (
  activeStep: TSteps,
  labels: TExperienceBuilderLabels
): string => {
  switch (activeStep) {
    case "tripDetails":
      return labels.TripDetails.StepTitle;
    case "courses":
      return labels.Courses.StepTitle;
    case "hotels":
      return labels.Hotels.StepTitle;
    case "tours":
      return labels.Tours.StepTitle;
    case "transport":
      return labels.Transport.StepTitle;
    case "yourDetails":
      return labels.YourDetails.StepTitle;

    default:
      return "";
  }
};

function StepsNavigation() {
  const {
    navigationSlice: { activeStep },
    setActiveStep,
    labels,
  } = useExperienceBuilderContext();

  const activeStepIndex = buttonKeys.indexOf(activeStep);

  return (
    <div className="u-wrapper o-tailored-experience__steps-navigation">
      {buttonKeys.map((buttonKey, index) => {
        const btnCss =
          activeStepIndex !== index
            ? `a-button--outlined-dark`
            : "a-button--dark";
        const isDisabled = activeStepIndex <= index;

        return (
          <div
            key={buttonKey}
            className="o-tailored-experience__steps-navigation__item"
          >
            <Button
              text={index + 1}
              onClick={() => setActiveStep(buttonKey)}
              extraClass={`a-button--number-only ${btnCss} ${
                isDisabled ? "disabled" : ""
              }`}
              disabled={activeStepIndex <= index}
            />
            <span>{getStepTitle(buttonKey, labels)}</span>
          </div>
        );
      })}
    </div>
  );
}

export default StepsNavigation;
