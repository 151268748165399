import { useExperienceBuilderContext } from "components/ExperienceBuilderEG/context/ExperienceBuilderContext";
import DOMPurify from "dompurify";
import { Button } from "shared";
import { ButtonVariants } from "shared/Button/Button";

function Transport() {
  const { labels, setActiveStep, selectedTransport, setSelectedTransport } =
    useExperienceBuilderContext();

  return (
    <div className="m-courses m-transport">
      <div className="u-wrapper">
        <Button
          extraClass="back-btn"
          text="Back"
          variant={ButtonVariants.Naked}
          onClick={() => setActiveStep("tours")}
        />
        <h2 className="a-title__main">{labels.Transport.Title}</h2>
        <div className="a-title__short-text">
          <p
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(labels.Transport.Description),
            }}
          ></p>
        </div>
      </div>
      <hr className="u-divider" />
      <div className="u-wrapper">
        <div className="m-form__group m-form__group--radio m-transport__options">
          {labels.Transport.TransportOptions.map((option) => {
            return (
              <label
                key={option.Title}
                className={`m-form__radio  ${
                  selectedTransport.Title === option.Title ? "is-checked" : ""
                }`}
                onClick={() => setSelectedTransport(option)}
              >
                {option.ImgSrc && (
                  <div className="m-transport__options-img-wrap">
                    <img src={option.ImgSrc} alt={option.Title} />
                  </div>
                )}

                <div className="m-transport__options-text">
                  <h4>{option.Title}</h4>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(option.Description),
                    }}
                  ></span>
                </div>
                <input
                  className="m-form__radio-input"
                  id={option.Title}
                  name={option.Title}
                  type="radio"
                />
                <label
                  htmlFor={option.Title}
                  className="m-form__radio-label"
                ></label>
              </label>
            );
          })}
        </div>
      </div>
      <hr className="u-divider" />
      <div className="u-wrapper">
        <div className="m-transport__footer">
          <Button
            text="Next"
            onClick={() => setActiveStep("yourDetails")}
            endIcon={<i className="zmdi zmdi-long-arrow-right"></i>}
          />
        </div>
      </div>
    </div>
  );
}

export default Transport;
