import { debounce } from "helpers";
import { useCallback, useState } from "react";
import { Select } from "shared";
import axios from "axios";
import { useStyleContext } from "components/StyleContext/StyleContext";
import { STYLES } from "../../../../constants";
import { IconName } from "shared/IconComponent/IconComponent";
import { useExperienceBuilderContext } from "components/ExperienceBuilderEG/context/ExperienceBuilderContext";
import { TOption } from "shared/Select/Select";
import { TExperienceBuilderCountry } from "components/ExperienceBuilderEG/ExperienceBuilderEG.types";
import { SingleValue } from "react-select";

type TSearchedCourses = {
  Country: {
    Regions: { Name: string; Id: string }[];
    Name: string;
    Id: string;
  };
  Name: string;
  Value: string;
};

function SuggestiveSearchInput({
  countries,
  noOptionsMessage,
}: {
  countries: TExperienceBuilderCountry[];
  noOptionsMessage: string;
}) {
  const { style } = useStyleContext();
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const { labels, domain, setActiveCountriesAndRegion } =
    useExperienceBuilderContext();
  const [searchTerm, setSearchTerm] = useState("");

  const [loadedOptions, setLoadedOptions] = useState<TSearchedCourses[]>([]);
  const [defaultOptions, setDefaultOptions] = useState<TOption[]>([]);

  // eslint-disable-next-line
  const loadSuggestiveSearchOptions = useCallback(
    debounce(async (query: string, callback: (options: any) => void) => {
      return query?.length >= 2
        ? axios
            .get(domain + labels.Courses.SearchApiRoute + `?word=${query}`)
            .then((res: { data: TSearchedCourses[] }) => {
              setLoadedOptions(res?.data ?? []);
              const options = res?.data?.map((opt: TSearchedCourses) => ({
                label: opt?.Name,
                value: opt?.Name,
              }));

              setDefaultOptions(options);
              callback(options);
            })
        : callback([]);
    }, 500),

    []
  );

  return (
    <Select
      isAsync
      defaultOptions={defaultOptions}
      placeholder={labels.Courses.SelectYourCoursesPlaceholder}
      loadOptions={loadSuggestiveSearchOptions}
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          setIsMenuOpen(false);
          const countryIds = loadedOptions.map((option) => option.Country.Id);
          const regionIds = loadedOptions.flatMap((option) =>
            option.Country.Regions.map((region) => region.Id)
          );

          const selectedCountries = countries.filter((country) =>
            countryIds.includes(country.Id)
          );
          const selectedRegions = countries
            .flatMap((country) => country.Regions)
            .filter((region) => regionIds.includes(region.Id));

          setActiveCountriesAndRegion(selectedCountries, selectedRegions);
          event.preventDefault();
        }
      }}
      value={null}
      onChange={(value) => {
        const selectedValue = loadedOptions.find(
          (option) => option.Name === (value as SingleValue<TOption>)?.label
        );

        if (!selectedValue) {
          return;
        }

        const country = countries.filter(
          (country) => country.Id === selectedValue?.Country.Id
        );
        const regionIds = selectedValue.Country.Regions.map(
          (region) => region.Id
        );
        const selectedRegions = countries
          .flatMap((country) => country.Regions)
          .filter((region) => regionIds.includes(region.Id));

        setActiveCountriesAndRegion(country, selectedRegions);

        console.log(selectedValue);
      }}
      inputValue={searchTerm}
      onInputChange={(value, actionMeta) => {
        if (!["input-blur", "menu-close"].includes(actionMeta?.action)) {
          setSearchTerm(value);
        }

        return value;
      }}
      menuIsOpen={isMenuOpen}
      onMenuOpen={() => setIsMenuOpen(true)}
      onMenuClose={() => {
        setIsMenuOpen(false);
      }}
      noOptionsMessage={() => noOptionsMessage}
      iconName={style === STYLES.EG ? IconName.Question : undefined}
      formatOptionLabel={({ label }) => label}
    />
  );
}

export default SuggestiveSearchInput;
