import StepsNavigation from "../StepsNavigation";
import { renderComponentBasedOnActiveKey } from "components/ExperienceBuilderEG/ExperienceBuilderEG.utils";
import { useExperienceBuilderContext } from "components/ExperienceBuilderEG/context/ExperienceBuilderContext";

const ExperienceBuilderPage = () => {
  const {
    navigationSlice: { activeStep },
  } = useExperienceBuilderContext();

  return (
    <div>
      {activeStep !== "confirmation" && <StepsNavigation />}
      {renderComponentBasedOnActiveKey(activeStep)}
    </div>
  );
};

export default ExperienceBuilderPage;
