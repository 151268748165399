import { useStyleContext } from "components/StyleContext/StyleContext";
import { STYLES } from "../../constants";
import { ReactNode } from "react";

export enum ButtonVariants {
  Outlined = "outlined",
  Default = "default",
  Naked = "naked",
}

export enum ButtonSizes {
  Default = "default",
  Small = "small",
}

type TButtonProps = {
  text: string | ReactNode;
  onClick: () => void;
  type?: "button" | "link";
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  variant?: ButtonVariants;
  size?: ButtonSizes;
  disabled?: boolean;
  extraClass?: string;
};

const styleMap = {
  [STYLES.KP]: {
    baseBtnClass: "btn",
    size: {
      [ButtonSizes.Default]: "",
      [ButtonSizes.Small]: "btn--small",
    },
    variant: {
      [ButtonVariants.Outlined]: "btn-default btn-std-bordered",
      [ButtonVariants.Default]: "btn-default btn-std-clear",
      [ButtonVariants.Naked]: "btn-default btn-std-clear",
    },
  },
  [STYLES.EG]: {
    baseBtnClass: "a-button",
    size: {
      [ButtonSizes.Default]: "",
      [ButtonSizes.Small]: "",
    },
    variant: {
      [ButtonVariants.Outlined]: "a-button--naked a-button--outlined",
      [ButtonVariants.Naked]: "a-button--naked",
      [ButtonVariants.Default]: "",
    },
  },
};

function Button({
  text,
  onClick,
  type = "button",
  startIcon,
  endIcon,
  variant = ButtonVariants.Default,
  size = ButtonSizes.Default,
  extraClass = "",
  disabled = false,
}: TButtonProps) {
  const { style } = useStyleContext();
  const renderIcon = (icon: React.ReactNode, baseClass: string) => (
    <span className={`${baseClass}__icon`}>{icon}</span>
  );

  if (type === "link") {
    const baseClass = "a-link";
    return (
      <button onClick={onClick} className={`${baseClass} ${extraClass}`}>
        {startIcon && renderIcon(startIcon, baseClass)}
        <span className="a-link__text">{text}</span>
        {endIcon && renderIcon(endIcon, baseClass)}
      </button>
    );
  }

  if (type === "button") {
    const variantCssClasses = styleMap[style].variant;
    const sizeCssClasses = styleMap[style].size;

    return (
      <button
        onClick={onClick}
        type="button"
        className={`${styleMap[style].baseBtnClass} ${variantCssClasses[variant]} ${sizeCssClasses[size]} ${extraClass}`}
        disabled={disabled}
      >
        {text}
        {endIcon}
      </button>
    );
  }

  return <></>;
}

export default Button;
