import { useExperienceBuilderContext } from "components/ExperienceBuilderEG/context/ExperienceBuilderContext";
import { Button, Checkbox, DatePicker, Select } from "shared";
import NumberedInput from "./NumberedInput";
import { useMemo, useState } from "react";
import { IconName } from "shared/IconComponent/IconComponent";
import { TOption } from "shared/Select/Select";
import ImageWithText from "./ImageWithText";
import DOMPurify from "dompurify";
import * as R from "ramda";

export type TTripDetails = {
  noOfGolfers: number;
  noOfNonGolfers: number;
  arrivalDate: Date | null;
  isNotSpecificDate: boolean;
  tripDuration: TOption | null;
};

function TripDetails() {
  const { setActiveStep, setTripDetails, tripDetails, labels } =
    useExperienceBuilderContext();
  const [formValues, setFormValues] = useState<TTripDetails>(tripDetails);
  const [formErrors, setFormErrors] = useState<Record<string, string>>({});

  const tripDurationOptions: TOption[] = useMemo(
    () =>
      labels.TripDetails.TripDurationValues.map((value) => ({
        label: value,
        value,
      })),
    [labels.TripDetails.TripDurationValues]
  );

  const validateForm = () => {
    let errors: Record<string, string> = {};

    if (formValues.noOfGolfers === 0 && formValues.noOfNonGolfers === 0) {
      errors.noOfGolfers = labels.TripDetails.NoOfGolfersErrorMessage;
    }

    if (!formValues.tripDuration) {
      errors.tripDuration = labels.TripDetails.TripDurationErrorMessage;
    }

    setFormErrors(errors);

    return Object.keys(errors).length > 0;
  };

  const onSubmit = () => {
    const validationResult = validateForm();

    if (!validationResult) {
      setTripDetails(formValues);
      setActiveStep("courses");
    }
  };

  return (
    <>
      <div className="u-wrapper m-trip-details">
        <div className="m-trip-details__left">
          <div className="a-title a-title--with-h1 ">
            <h1 className="a-title__main">{labels.TripDetails.Title}</h1>
          </div>
          <div
            className="page-description"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(labels.TripDetails.Description),
            }}
          ></div>
          <hr
            style={{ marginTop: "-20px" }}
            className="u-divider u-divider--mobile-full-width hide-from-tablet"
          ></hr>

          <div className="form-heading">{labels.TripDetails.YourTrip}</div>
          <div className="golfers-input">
            <NumberedInput
              label={labels.TripDetails.NoOfGolfers}
              count={formValues.noOfGolfers}
              onPlusClick={() => {
                setFormErrors((formErrors) =>
                  R.omit(["noOfGolfers"], formErrors)
                );
                setFormValues((values) => ({
                  ...values,
                  noOfGolfers: values.noOfGolfers + 1,
                }));
              }}
              onMinusClick={() =>
                setFormValues((values) => ({
                  ...values,
                  noOfGolfers: values.noOfGolfers - 1,
                }))
              }
              isError={Boolean(formErrors.noOfGolfers)}
            />
            <NumberedInput
              label={labels.TripDetails.NoOfNonGolfers}
              count={formValues.noOfNonGolfers}
              onPlusClick={() => {
                setFormErrors((formErrors) =>
                  R.omit(["noOfGolfers"], formErrors)
                );
                setFormValues((values) => ({
                  ...values,
                  noOfNonGolfers: values.noOfNonGolfers + 1,
                }));
              }}
              onMinusClick={() =>
                setFormValues((values) => ({
                  ...values,
                  noOfNonGolfers: values.noOfNonGolfers - 1,
                }))
              }
              isError={Boolean(formErrors.noOfGolfers)}
            />
            {Boolean(formErrors.noOfGolfers) && (
              <span className="field-validation-error">
                {formErrors.noOfGolfers}
              </span>
            )}
          </div>
          <DatePicker
            variant="outsideLabel"
            onChange={(date) =>
              setFormValues((values) => ({
                ...values,
                isNotSpecificDate: !Boolean(date),
                arrivalDate: date,
              }))
            }
            value={formValues.arrivalDate}
            label={labels.TripDetails.ArrivalDate}
            placeholder={labels.TripDetails.ArrivalDatePlaceholder}
            iconName={IconName.DownChevron}
            dateFormat="MM/dd/yyyy"
            disablePast
          />
          <Checkbox
            id={"no-specific-arrival-date"}
            name={"no-specific-arrival-date"}
            checked={formValues.isNotSpecificDate}
            onChange={(value) => {
              setFormValues((values) => ({
                ...values,
                arrivalDate: null,
                isNotSpecificDate: value,
              }));
            }}
            label={labels.TripDetails.NoSpecificDate}
          />
          <Select
            label={labels.TripDetails.TripDuration}
            options={tripDurationOptions}
            isSearchable={false}
            value={formValues.tripDuration}
            onChange={(option) => {
              setFormErrors((formErrors) =>
                R.omit(["tripDuration"], formErrors)
              );
              setFormValues((values) => ({
                ...values,
                tripDuration: option as TOption,
              }));
            }}
            error={formErrors.tripDuration}
          />
          <Button
            text={labels.Common.GetStartedBtn}
            onClick={onSubmit}
            endIcon={<i className="zmdi zmdi-long-arrow-right"></i>}
          />
          <hr
            style={{ marginTop: "40px" }}
            className="u-divider u-divider--mobile-full-width hide-from-tablet"
          ></hr>
        </div>
        <div className="m-trip-details__right">
          <span>{labels.TripDetails.RightSideTitle}</span>
          <ImageWithText
            imageSrc={labels.TripDetails.ImageOne}
            text={labels.TripDetails.ImageOneTopText}
            icon={labels.TripDetails.ImageOneTopTextIcon}
          />
          <div className="m-trip-details__right__row">
            <ImageWithText
              imageSrc={labels.TripDetails.ImageTwo}
              text={labels.TripDetails.ImageTwoTopText}
              icon={labels.TripDetails.ImageTwoTopTextIcon}
            />
            <ImageWithText
              imageSrc={labels.TripDetails.ImageThree}
              text={labels.TripDetails.ImageThreeTopText}
              icon={labels.TripDetails.ImageThreeTopTextIcon}
            />
          </div>
        </div>
      </div>
      <hr className="u-divider" />
    </>
  );
}

export default TripDetails;
