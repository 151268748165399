type TTextInputProps = {
  onChange: (value: string) => void;
  value: string;
  placeholder: string;
  type?: "text" | "tel" | "email" | "number";
  className?: string;
  error?: string;
};

function TextInput(props: TTextInputProps) {
  return (
    <div className="m-form__group">
      <input
        onChange={(event) => props.onChange(event.target.value)}
        value={props.value}
        placeholder={props.placeholder}
        className={`m-form__input ${props.className ?? ""} ${
          Boolean(props.error) ? "input-validation-error" : ""
        }`}
        type={props.type ?? "text"}
      />
      {Boolean(props.error) && (
        <span className="field-validation-error" style={{ marginBottom: 0 }}>
          {props.error}
        </span>
      )}
    </div>
  );
}

export default TextInput;
