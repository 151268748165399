import { TExperienceBuilderCourse } from "components/ExperienceBuilderEG/ExperienceBuilderEG.types";
import { useExperienceBuilderContext } from "components/ExperienceBuilderEG/context/ExperienceBuilderContext";
import { useAPI } from "hooks";
import React, { useMemo } from "react";
import { Button, Loader } from "shared";
import * as R from "ramda";
import CourseItem from "./CourseItem";

function CoursesList() {
  const {
    labels,
    domain,
    filtersSlice,
    setSelectedCourses,
    selectedCourses,
    isRegionsDirty,
    setActiveStep,
  } = useExperienceBuilderContext();

  const payload = useMemo(() => {
    if (filtersSlice.selectedRegions.length) {
      return filtersSlice.selectedRegions.map((region) => region.Id);
    }
    if (
      !filtersSlice.selectedRegions.length &&
      filtersSlice.selectedCountries.length
    ) {
      return R.uniq(
        filtersSlice.selectedCountries
          .flatMap((country) => country.Regions)
          .map((region) => region.Id)
      );
    }

    return [];
  }, [filtersSlice.selectedRegions, filtersSlice.selectedCountries]);

  const { data: courses, isLoading: isCoursesLoading } = useAPI<{
    Courses: TExperienceBuilderCourse[];
  }>({
    url: domain + labels.Courses.ApiRoute,
    method: "POST",
    payload,
    isEnabled: payload.length > 0 || isRegionsDirty,
  });

  const coursesList = useMemo(
    () =>
      R.groupBy(
        (course: TExperienceBuilderCourse) => course.LocationString,
        courses?.Courses ?? []
      ),
    [courses?.Courses]
  );

  const handleCourseSelection = (
    value: boolean,
    courseItem: TExperienceBuilderCourse
  ) => {
    if (value) {
      setSelectedCourses([...selectedCourses, courseItem]);
    } else {
      setSelectedCourses(
        selectedCourses.filter((course) => course.Id !== courseItem.Id)
      );
    }
  };

  return (
    <>
      {isCoursesLoading ? (
        <Loader />
      ) : (
        <div>
          {Object.keys(coursesList).map((locationStringKey) => (
            <React.Fragment key={locationStringKey}>
              <div className="m-course-list__section">
                <span>{locationStringKey}</span>
                <span>{`${coursesList[locationStringKey]?.length} ${labels.Courses.CoursesText}`}</span>
              </div>
              <div className="m-course-list">
                {coursesList[locationStringKey]?.map((course) => (
                  <CourseItem
                    key={course.Title}
                    course={course}
                    isSelected={Boolean(
                      selectedCourses.find(
                        (selectedCourse) => course.Id === selectedCourse.Id
                      )
                    )}
                    onChange={(value) => handleCourseSelection(value, course)}
                  />
                ))}
              </div>
            </React.Fragment>
          ))}
        </div>
      )}
      <div className="m-courses__footer">
        <Button
          disabled={!filtersSlice.selectedCountries.length || isCoursesLoading}
          text={labels.Common.NextBtn}
          onClick={() => setActiveStep("hotels")}
          endIcon={<i className="zmdi zmdi-long-arrow-right"></i>}
        />
        <span>
          {selectedCourses.length >= 1 && <i className="zmdi zmdi-check"></i>}
          &nbsp;&nbsp;
          {`${selectedCourses.length} ${labels.Courses.CoursesSelected}`}
        </span>
      </div>
      <hr
        style={{ marginTop: "20px", marginBottom: "40px" }}
        className="u-divider u-divider--mobile-full-width hide-from-tablet"
      ></hr>
    </>
  );
}

export default CoursesList;
