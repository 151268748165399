import { ReactNode } from "react";
import { Button } from "shared";

type TCollapsibleProps = {
  children: ReactNode;
  title: string;
  subtitle: string;
  isExpanded: boolean;
  setIsExpanded: (value: boolean) => void;
};

function Collapsible({
  children,
  title,
  subtitle,
  isExpanded,
  setIsExpanded,
}: TCollapsibleProps) {
  return (
    <div className="m-collapsible">
      <div
        className="m-collapsible__header"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <h5>
          <i className="zmdi zmdi-filter-list" />
          <span>{title}</span>
        </h5>
        <div>
          <span>{subtitle}</span>
          <Button
            extraClass="a-button--icon-only"
            text={
              <i
                className={`zmdi ${
                  isExpanded ? "zmdi-chevron-up" : "zmdi-chevron-down"
                }`}
              />
            }
            onClick={() => setIsExpanded(!isExpanded)}
          />
        </div>
      </div>
      <div className={`m-collapsible__content ${!isExpanded ? "hidden" : ""}`}>
        {children}
      </div>
    </div>
  );
}

export default Collapsible;
