import { TExperienceBuilderHotel } from "components/ExperienceBuilderEG/ExperienceBuilderEG.types";
import { Checkbox } from "shared";

type THotelItemProps = {
  hotel: TExperienceBuilderHotel;
  isSelected: boolean;
  onChange: (value: boolean) => void;
};

function HotelItem({ hotel, isSelected, onChange }: THotelItemProps) {
  return (
    <div
      className="m-square-card u-text-white"
      style={{ cursor: "pointer" }}
      onClick={() => onChange(!isSelected)}
    >
      <div className="m-square-card__top-wrap">
        <img
          src={hotel.BgImage}
          alt={hotel.Title}
          className="m-square-card__image"
        />
        <div className="m-square-card__top">
          <Checkbox
            id={hotel.Title}
            name={hotel.Title}
            checked={isSelected}
            onChange={onChange}
          />
        </div>
      </div>
      <div className="m-square-card__content">
        <h2 className="m-square-card__title">{hotel.Title}</h2>
        <ul className="m-square-card__info">
          <li className="m-square-card__item">
            {Array.from(Array(hotel.Rating).keys()).map((idx) => (
              <i className="zmdi zmdi-star"></i>
            ))}
          </li>
          <li className="m-square-card__item">{hotel.InfoText}</li>
        </ul>
      </div>
    </div>
  );
}

export default HotelItem;
