import ExperienceBuilderEG from "components/ExperienceBuilderEG";
import { lazy } from "react";

const Calendar = lazy(() => import("./components/Calendar"));
const EventSearch = lazy(() => import("./components/EventSearch"));
const Blog = lazy(() => import("./components/Blog"));
const BlogEG = lazy(() => import("./components/BlogEG"));
const Playground = lazy(() => import("./components/Playground"));
const MyBookingsEG = lazy(() => import("./components/MyBookingsEG"));
const PaymentHistoryEG = lazy(() => import("./components/PaymentHistoryEG"));
const RoomBookingEG = lazy(() => import("./components/RoomBookingEG"));
const ExperienceSearchEG = lazy(
  () => import("./components/ExperienceSearchEG")
);
const ExperienceListEG = lazy(() => import("./components/ExperienceListEG"));
const BasketEG = lazy(() => import("./components/BasketEG"));
const Fixtures = lazy(() => import("./components/Fixtures"));
export const DOM_ELEMENT_CLASS = "react-component";
export const DOM_ELEMENT_ID = "react-component";

export enum Components {
  Calendar = "Calendar",
  EventSearch = "EventSearch",
  Blog = "Blog",
  BlogEG = "BlogEG",
  BasketEG = "BasketEG",
  Playground = "Playground",
  Fixtures = "Fixtures",
  MyBookingsEG = "MyBookingsEG",
  PaymentHistoryEG = "PaymentHistoryEG",
  RoomBookingEG = "RoomBookingEG",
  ExperienceListEG = "ExperienceListEG",
  ExperienceSearchEG = "ExperienceSearchEG",
  ExperienceBuilderEG = "TailoredExperience",
}

export enum STYLES {
  KP = "KP",
  EG = "EG",
}

export const COMPONENT_MAP = {
  [Components.Calendar]: Calendar,
  [Components.EventSearch]: EventSearch,
  [Components.Blog]: Blog,
  [Components.Playground]: Playground,

  [Components.BlogEG]: BlogEG,
  [Components.MyBookingsEG]: MyBookingsEG,
  [Components.PaymentHistoryEG]: PaymentHistoryEG,
  [Components.RoomBookingEG]: RoomBookingEG,
  [Components.ExperienceSearchEG]: ExperienceSearchEG,
  [Components.ExperienceListEG]: ExperienceListEG,
  [Components.BasketEG]: BasketEG,
  [Components.Fixtures]: Fixtures,
  [Components.ExperienceBuilderEG]: ExperienceBuilderEG,
};
