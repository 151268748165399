import { TExperienceBuilderCourse } from "components/ExperienceBuilderEG/ExperienceBuilderEG.types";
import { Checkbox } from "shared";

type TCourseItemProps = {
  course: TExperienceBuilderCourse;
  isSelected: boolean;
  onChange: (value: boolean) => void;
};

function CourseItem({ course, isSelected, onChange }: TCourseItemProps) {
  return (
    <div
      className="m-square-card u-text-white"
      style={{ cursor: "pointer" }}
      onClick={() => onChange(!isSelected)}
    >
      {course.TagText && (
        <span className="m-square-card__tag">{course.TagText}</span>
      )}

      <div className="m-square-card__top-wrap">
        <img
          src={course.BgImage}
          alt={course.Title}
          className="m-square-card__image"
        />
        <div className="m-square-card__top">
          <Checkbox
            id={course.Title}
            name={course.Title}
            checked={isSelected}
            onChange={onChange}
          />
        </div>
      </div>
      <div className="m-square-card__content">
        <h2 className="m-square-card__title">{course.Title}</h2>
        <ul className="m-square-card__info">
          <li className="m-square-card__item">{course.InfoText}</li>
        </ul>
      </div>
    </div>
  );
}

export default CourseItem;
