import { useExperienceBuilderContext } from "components/ExperienceBuilderEG/context/ExperienceBuilderContext";
import { Button } from "shared";
import { ButtonVariants } from "shared/Button/Button";
import CoursesList from "./CoursesList";
import LocationFilters from "./LocationFilters";
import DOMPurify from "dompurify";
import GoogleMap from "../GoogleMap";

function Courses() {
  const { setActiveStep, labels } = useExperienceBuilderContext();

  return (
    <div className="m-courses">
      <div className="u-wrapper">
        <Button
          extraClass="back-btn"
          text="Back"
          variant={ButtonVariants.Naked}
          onClick={() => setActiveStep("tripDetails")}
        />
        <h2 className="a-title__main">{labels.Courses.Title}</h2>
        <div className="a-title__short-text">
          <p
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(labels.Courses.Description),
            }}
          ></p>
        </div>
      </div>
      <hr className="u-divider" />

      <div className="u-wrapper">
        <div className="m-courses__content">
          <div className="m-courses__content--left">
            <LocationFilters />
            <CoursesList />
          </div>
          <div>
            <GoogleMap />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Courses;
