import {
  TExperienceBuilderCountry,
  TExperienceBuilderRegion,
} from "components/ExperienceBuilderEG/ExperienceBuilderEG.types";
import { useExperienceBuilderContext } from "components/ExperienceBuilderEG/context/ExperienceBuilderContext";
import { useAPI } from "hooks";
import React, { useCallback, useMemo, useState } from "react";
import { Button, Checkbox, Loader } from "shared";
import Collapsible from "./Collapsible";
import SuggestiveSearchInput from "./SuggestiveSearchInput";

function LocationFilters() {
  const {
    labels,
    domain,
    locationsPath,
    filtersSlice,
    setSelectedCountries,
    setSelectedRegions,
    isFiltersDirty,
    navigationSlice: { activeStep },
  } = useExperienceBuilderContext();

  const [isCountriesExpanded, setIsCountriesExpanded] = useState(
    !isFiltersDirty
  );
  const [isRegionsExpanded, setIsRegionsExpanded] = useState(
    isFiltersDirty && !filtersSlice.selectedRegions.length
  );

  const { data: locations, isLoading: isLocationsLoading } = useAPI<{
    Countries: TExperienceBuilderCountry[];
  }>({
    url: domain + locationsPath + "?locationSource=" + labels.LocationSource,
    method: "GET",
  });

  const isDisabled = activeStep !== "courses";

  const handleCountryCheckboxChange = useCallback(
    (country: TExperienceBuilderCountry, value: boolean) => {
      setIsRegionsExpanded(true);
      if (value) {
        setSelectedCountries([...filtersSlice.selectedCountries, country]);
      } else {
        setSelectedCountries(
          filtersSlice.selectedCountries.filter(
            (existingCountry) => existingCountry.Id !== country.Id
          )
        );
      }
    },
    [filtersSlice.selectedCountries, setSelectedCountries]
  );

  const handleRegionCheckboxChange = useCallback(
    (region: TExperienceBuilderRegion, value: boolean) => {
      if (value) {
        setSelectedRegions([...filtersSlice.selectedRegions, region]);
      } else {
        setSelectedRegions(
          filtersSlice.selectedRegions.filter(
            (existingRegion) => existingRegion.Id !== region.Id
          )
        );
      }
    },
    [filtersSlice.selectedRegions, setSelectedRegions]
  );

  const selectedCountriesIds = useMemo(
    () => filtersSlice.selectedCountries.map((country) => country.Id),
    [filtersSlice.selectedCountries]
  );

  const selectedRegionIds = useMemo(
    () => filtersSlice.selectedRegions.map((region) => region.Id),
    [filtersSlice.selectedRegions]
  );

  return isLocationsLoading ? (
    <Loader />
  ) : (
    <>
      {!isDisabled && (
        <div className="m-courses__search">
          <h4>{labels.Courses.SelectYourCourses}</h4>
          <SuggestiveSearchInput
            countries={locations?.Countries ?? []}
            noOptionsMessage={labels.Common.NoResults}
          />
        </div>
      )}

      <Collapsible
        isExpanded={isCountriesExpanded}
        setIsExpanded={setIsCountriesExpanded}
        title={labels.Common.FilterByCountry}
        subtitle={`${selectedCountriesIds.length} ${labels.Common.FiltersApplied}`}
      >
        {locations?.Countries.length ? (
          <div className="m-collapsible__checkbox-list">
            {locations.Countries.map((country) => {
              if (isDisabled && !selectedCountriesIds.includes(country.Id)) {
                return <React.Fragment key={country.Id}></React.Fragment>;
              }

              return (
                <Checkbox
                  key={country.Id}
                  name={country.Id}
                  id={country.Id}
                  label={country.Name}
                  checked={selectedCountriesIds.includes(country.Id)}
                  onChange={(value) =>
                    handleCountryCheckboxChange(country, value)
                  }
                  disabled={isDisabled}
                />
              );
            })}
          </div>
        ) : (
          <div>no countries</div>
        )}
      </Collapsible>
      <Collapsible
        isExpanded={isRegionsExpanded}
        setIsExpanded={setIsRegionsExpanded}
        title={labels.Common.FilterByRegion}
        subtitle={`${selectedRegionIds.length} ${labels.Common.FiltersApplied}`}
      >
        {filtersSlice.selectedCountries.length ? (
          <div>
            {filtersSlice.selectedCountries.map((country) => {
              const countOfSelectedRegions = country.Regions.filter((region) =>
                selectedRegionIds.includes(region.Id)
              ).length;

              if (isDisabled && !countOfSelectedRegions) {
                return <React.Fragment key={country.Id}></React.Fragment>;
              }
              return (
                <React.Fragment key={country.Id}>
                  <div className="m-collapsible__country-name">
                    {country.Name}
                  </div>
                  <div className="m-collapsible__checkbox-list">
                    {country.Regions.map((region) => {
                      if (
                        isDisabled &&
                        !selectedRegionIds.includes(region.Id)
                      ) {
                        return <></>;
                      }

                      return (
                        <Checkbox
                          key={region.Id}
                          name={region.Id}
                          id={region.Id}
                          label={region.Name}
                          checked={selectedRegionIds.includes(region.Id)}
                          onChange={(value) =>
                            handleRegionCheckboxChange(region, value)
                          }
                          disabled={isDisabled}
                        />
                      );
                    })}
                  </div>
                </React.Fragment>
              );
            })}
          </div>
        ) : (
          <div>no regions for selected countries</div>
        )}
      </Collapsible>
      <Button
        type="link"
        text={labels.Common.ViewSelectionOnMap}
        extraClass="view-map-button hide-from-tablet"
        onClick={() => {
          const componentContainer =
            document.getElementsByClassName("m-tailor-map")?.[0];

          if (componentContainer) {
            componentContainer.scrollIntoView({ behavior: "smooth" });
          }
        }}
        endIcon={<i className="zmdi zmdi-map" />}
      />
    </>
  );
}

export default LocationFilters;
