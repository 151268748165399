import { Button } from "shared";

type TNumberedInputProps = {
  label: string;
  count: number;
  onPlusClick: () => void;
  onMinusClick: () => void;
  isError?: boolean;
};

function NumberedInput({
  label,
  onMinusClick,
  onPlusClick,
  count,
  isError,
}: TNumberedInputProps) {
  return (
    <div className="numbered-input">
      <label htmlFor="inputField" className="numbered-input__label">
        {label}
      </label>
      <div className="numbered-input__bottom">
        <Button
          extraClass="a-button--icon-only"
          text={<i className="zmdi zmdi-minus" />}
          onClick={onMinusClick}
          disabled={count === 0}
        />
        <input
          id="inputField"
          type="text"
          value={count || 0}
          onChange={() => {}}
          disabled
          className={isError ? "input-validation-error" : ""}
        />
        <Button
          extraClass="a-button--icon-only"
          text={<i className="zmdi zmdi-plus" />}
          onClick={onPlusClick}
        />
      </div>
    </div>
  );
}

export default NumberedInput;
