import { useCallback, useMemo } from "react";
import qs from "qs";
import * as R from "ramda";
import { useSearchParams } from "react-router-dom";

const useQueryParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const queryParams = useMemo(
    () => qs.parse(searchParams?.toString()),
    [searchParams]
  );

  const setQueryParams = useCallback(
    (params: any) => {
      const newParams = R.reject(R.equals(""))(params);
      setSearchParams(newParams, { replace: true });

      return newParams;
    },
    [setSearchParams]
  );

  const mergeQueryParams = useCallback(
    (params: any) => {
      const newParams = R.mergeRight(queryParams, params);

      return setQueryParams(newParams);
    },
    [setQueryParams, queryParams]
  );

  const setQueryParam = useCallback(
    (name: string, value: any) =>
      setQueryParams(R.assoc(name, value, queryParams)),
    [setQueryParams, queryParams]
  );

  return {
    queryParams,
    setQueryParam,
    setQueryParams,
    mergeQueryParams,
  };
};

export default useQueryParams;
