import Calendar from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CustomInput from "./components/CustomInput";
import { IconName } from "shared/IconComponent/IconComponent";

type TDatePickerProps = {
  label: string;
  placeholder?: string;
  value: Date | null;
  onChange: (Date: Date | null) => void;
  variant: "insideLabel" | "outsideLabel";
  iconName?: IconName;
  dateFormat?: string;
  isClearable?: boolean;
  disablePast?: boolean;
};

function DatePicker({
  label,
  placeholder,
  value,
  onChange,
  variant = "insideLabel",
  iconName,
  isClearable,
  dateFormat = "EEEE do MMMM yyyy",
  disablePast = false,
}: TDatePickerProps) {
  return (
    <div className="m-date-picker">
      <Calendar
        isClearable={isClearable}
        selected={value}
        onChange={(date: Date | null) => onChange(date)}
        placeholderText={variant === "insideLabel" ? label : placeholder}
        label={label}
        popperPlacement="bottom"
        popperModifiers={{
          //@ts-ignore
          flip: { behavior: ["bottom"] },
          preventOverflow: { enabled: false },
          hide: { enabled: false },
          open: true,
        }}
        dateFormat={dateFormat}
        customInput={
          <CustomInput variant={variant} label={label} iconName={iconName} />
        }
        minDate={disablePast ? new Date() : undefined}
      />
    </div>
  );
}

export default DatePicker;
