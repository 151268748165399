import { useExperienceBuilderContext } from "components/ExperienceBuilderEG/context/ExperienceBuilderContext";
import DOMPurify from "dompurify";
import { Button } from "shared";

function Confirmation() {
  const { labels } = useExperienceBuilderContext();

  return (
    <>
      <div className="u-wrapper m-teg-confirmation">
        <div className="m-teg-confirmation__left">
          <h1 className="a-title__main">{labels.Confirmation.Title}</h1>
          <div className="a-title__short-text">
            <p
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(labels.Confirmation.Description),
              }}
            ></p>
          </div>
          <Button
            text={labels.Confirmation.BackToHomeBtn}
            onClick={() => {
              window.location.href = labels.Confirmation.BackToHomeLink;
            }}
            endIcon={<i className="zmdi zmdi-long-arrow-right"></i>}
          />
        </div>
        <div className="m-teg-confirmation__right">
          <img src={labels.Confirmation.ImgSrc} alt="confirmation" />
        </div>
      </div>
      <hr className="u-divider" />
    </>
  );
}

export default Confirmation;
