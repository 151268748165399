import { TExperienceBuilderHotel } from "components/ExperienceBuilderEG/ExperienceBuilderEG.types";
import { useExperienceBuilderContext } from "components/ExperienceBuilderEG/context/ExperienceBuilderContext";
import { useAPI } from "hooks";
import { useMemo } from "react";
import { Button, Loader } from "shared";
import * as R from "ramda";
import HotelItem from "./HotelItem";

function HotelsList() {
  const {
    labels,
    domain,
    filtersSlice,
    selectedHotels,
    setSelectedHotels,
    setActiveStep,
  } = useExperienceBuilderContext();

  const payload = useMemo(() => {
    if (filtersSlice.selectedRegions.length) {
      return filtersSlice.selectedRegions.map((region) => region.Id);
    }
    if (
      !filtersSlice.selectedRegions.length &&
      filtersSlice.selectedCountries.length
    ) {
      return R.uniq(
        filtersSlice.selectedCountries
          .flatMap((country) => country.Regions)
          .map((region) => region.Id)
      );
    }

    return [];
  }, [filtersSlice.selectedRegions, filtersSlice.selectedCountries]);

  const { data: hotels, isLoading: isHotelsLoading } = useAPI<{
    Hotels: TExperienceBuilderHotel[];
  }>({
    url: domain + labels.Hotels.ApiRoute,
    method: "POST",
    payload,
  });

  const hotelsList = useMemo(
    () =>
      R.groupBy(
        (hotel: TExperienceBuilderHotel) => hotel.LocationString,
        hotels?.Hotels ?? []
      ),
    [hotels?.Hotels]
  );

  const handleHotelSelection = (
    value: boolean,
    hotelItem: TExperienceBuilderHotel
  ) => {
    if (value) {
      setSelectedHotels([...selectedHotels, hotelItem]);
    } else {
      setSelectedHotels(
        selectedHotels.filter((hotel) => hotel.Id !== hotelItem.Id)
      );
    }
  };

  return (
    <>
      {isHotelsLoading ? (
        <Loader />
      ) : (
        <div>
          {Object.keys(hotelsList).length === 0 ? (
            <div className="o-tailored-experience__no-results">
              <div />
              <span>{labels.Hotels.NoResults}</span>
            </div>
          ) : (
            <>
              {Object.keys(hotelsList).map((locationStringKey) => (
                <>
                  <div className="m-course-list__section">
                    <span>{locationStringKey}</span>
                    <span>{`${hotelsList[locationStringKey]?.length} ${labels.Hotels.HotelsText}`}</span>
                  </div>
                  <div className="m-course-list">
                    {hotelsList[locationStringKey]?.map((hotel) => (
                      <HotelItem
                        key={hotel.Title}
                        hotel={hotel}
                        isSelected={Boolean(
                          selectedHotels.find(
                            (selectedHotel) => hotel.Id === selectedHotel.Id
                          )
                        )}
                        onChange={(value) => handleHotelSelection(value, hotel)}
                      />
                    ))}
                  </div>
                </>
              ))}
            </>
          )}
        </div>
      )}
      <div className="m-courses__footer">
        <Button
          disabled={isHotelsLoading}
          text={labels.Common.NextBtn}
          onClick={() => setActiveStep("tours")}
          endIcon={<i className="zmdi zmdi-long-arrow-right"></i>}
        />
        <span>
          {selectedHotels.length >= 1 && <i className="zmdi zmdi-check"></i>}
          &nbsp;&nbsp;
          {`${selectedHotels.length} ${labels.Hotels.HotelsSelected}`}
        </span>
      </div>
      <hr
        style={{ marginTop: "20px", marginBottom: "40px" }}
        className="u-divider u-divider--mobile-full-width hide-from-tablet"
      ></hr>
    </>
  );
}

export default HotelsList;
