import React, {
  FC,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  TExperienceBuilderCountry,
  TExperienceBuilderCourse,
  TExperienceBuilderHotel,
  TExperienceBuilderLabels,
  TExperienceBuilderRegion,
  TExperienceBuilderTour,
  TExperiencePersonalData,
  TExperienceTransportOption,
  TSteps,
} from "../ExperienceBuilderEG.types";
import { TTripDetails } from "../components/TripDetails/TripDetails";
import { TYourDetails } from "../components/YourDetails/YourDetails";
import { TToursDetails } from "../components/Tours/Tours";
import axios from "axios";

type TNavigationStateSlice = {
  activeStep: TSteps;
};

type TFiltersStateSlice = {
  selectedCountries: TExperienceBuilderCountry[];
  selectedRegions: TExperienceBuilderRegion[];
};

interface IExperienceBuilderContext {
  navigationSlice: TNavigationStateSlice;
  tripDetails: TTripDetails;
  yourDetails: TYourDetails;
  toursDetails: TToursDetails;
  onYourDetailsSubmit: (values: TYourDetails) => void;
  setTripDetails: React.Dispatch<React.SetStateAction<TTripDetails>>;
  setToursDetails: React.Dispatch<React.SetStateAction<TToursDetails>>;
  setActiveStep: (step: TSteps) => void;
  labels: TExperienceBuilderLabels;
  domain: string;
  locationsPath: string;
  filtersSlice: TFiltersStateSlice;
  setSelectedRegions: (newRegions: TExperienceBuilderRegion[]) => void;
  setSelectedCountries: (newCountries: TExperienceBuilderCountry[]) => void;
  selectedCourses: TExperienceBuilderCourse[];
  setSelectedCourses: (newCourses: TExperienceBuilderCourse[]) => void;
  selectedHotels: TExperienceBuilderHotel[];
  setSelectedHotels: (newHotels: TExperienceBuilderHotel[]) => void;
  selectedTours: TExperienceBuilderTour[];
  setSelectedTours: (newTours: TExperienceBuilderTour[]) => void;
  isFiltersDirty: boolean;
  isRegionsDirty: boolean;
  selectedTransport: TExperienceTransportOption;
  setSelectedTransport: (option: TExperienceTransportOption) => void;
  setActiveCountriesAndRegion: (
    countries: TExperienceBuilderCountry[],
    regions: TExperienceBuilderRegion[]
  ) => void;
  isSubmitLoading: boolean;
  submitError: string;
}

// THESE ARE THE FALLBACK VALUES
const defaultContext: IExperienceBuilderContext = {
  navigationSlice: {
    activeStep: "tripDetails",
  },
  tripDetails: {
    noOfGolfers: 0,
    noOfNonGolfers: 0,
    arrivalDate: null,
    isNotSpecificDate: false,
    tripDuration: null,
  },
  yourDetails: {
    golfingAssociation: "",
    title: null,
    firstName: "",
    lastName: "",
    email: "",
    countryCode: "+44",
    phoneNumber: "",
    isSubscriber: true,
  },
  toursDetails: {
    additionalRequirements: "",
  },
  selectedCourses: [],
  setSelectedCourses: () => {},
  selectedHotels: [],
  setSelectedHotels: () => {},
  selectedTours: [],
  setSelectedTours: () => {},
  setTripDetails: () => {},
  setToursDetails: () => {},
  onYourDetailsSubmit: () => {},
  setActiveStep: () => {},
  setActiveCountriesAndRegion: () => {},
  selectedTransport: {
    Title: "Yes, I require transport",
    Description: "lorem ipsum",
    ImgSrc: "",
  },
  setSelectedTransport: () => {},
  labels: {
    Common: {
      FilterByCountry: "Filter by country",
      FilterByRegion: "Filter by region",
      FiltersApplied: "filters applied",
      ViewSelectionOnMap: "View selections on map",
      LocationMap: "Location map:",
      MapDescription:
        "Your selections are marked on the map to help you plan your trip.",
      BackToTop: "Back to top",
      NextBtn: "Next",
      GetStartedBtn: "Get Started",
      BackBtn: "Back",
      SubmitBtn: "Submit",
      ErrorMessage: "An error occured!",
      NoResults: "No Results",
      SuccessMessage:
        "Data submitted with success. You will be contacted soon.",
    },
    YourDetails: {
      FormTitle: "Your details",
      GolfingAssociationPlaceholder: "Golfing Association (optional)",
      TitlePlaceholder: "Title",
      Titles: ["Mr.", "Ms.", "Miss"],
      FirstNamePlaceholder: "First name*",
      LastNamePlaceholder: "Last name*",
      EmailPlaceholder: "Email address*",
      CountryDialCodePlaceholder: "Country dial code*",
      PhoneNumberPlaceholder: "Phone number*",
      SubscribingText:
        "Stay up to date with the latest news and offers by subscribing to our newsletter",
      PrivacyPolicyText:
        "By submitting this form, I accept the <a href='/'><b>Privacy Policy.</b></a>",
      StepTitle: "Detail",
      Title: "Build your experience",
      Description:
        "Prow scuttle parrel provost Sail o shrouds spirits boom mizzenmast yardarm. Pinnace holystone mizzenmast quarter crow's nest nipperkin grog yardarm hempen halter furl.",
      ShowWhatHappensNext: false,
      SelectedCourses: "courses",
      SelectedHotels: "hotels",
      SelectedTours: "tours",
      CourseIcon: "",
      HotelIcon: "",
      TourIcon: "",
      ApiRoute: "/api/sitecore/tailoredexperience/submitwizard",
      TitleErrorMessage: "Title is required",
      FirstNameErrorMessage: "FirstName is required",
      LastNameErrorMessage: "LastName is required",
      EmailErrorMessage: "Email is invalid",
      CountryCodeErrorMessage: "Country Code is required",
      PhoneNumberErrorMessage: "Phone Number is required",
    },
    TripDetails: {
      YourTrip: "Your Trip",
      NoOfGolfers: "No Of Golfers",
      NoOfNonGolfers: "No Of Golfers",
      ArrivalDate: "Arrival Date",
      NoSpecificDate: "No Specific Arrival Date",
      ArrivalDatePlaceholder: "Select Arrival Date",
      TripDuration: "Trip Duration",
      TripDurationValues: ["5 days", "7 days", "20 days"],
      RightSideTitle: "Choose from:",
      ImageOne: "",
      ImageOneTopText: "20 courses",
      ImageOneTopTextIcon: '<i class="zmdi zmdi-flag"></i>',
      ImageTwo: "",
      ImageTwoTopText: "30 hotels",
      ImageTwoTopTextIcon: '<i class="zmdi zmdi-flag"></i>',
      ImageThree: "",
      ImageThreeTopText: "30 golf experiences",
      ImageThreeTopTextIcon: '<i class="zmdi zmdi-ticket-star"></i>',
      StepTitle: "Trip Details",
      Title: "Your trip",
      Description:
        "Prow scuttle parrel provost Sail o shrouds spirits boom mizzenmast yardarm. Pinnace holystone mizzenmast quarter crow's nest nipperkin grog yardarm hempen halter furl.",
      ShowWhatHappensNext: true,
      NoOfGolfersErrorMessage: "Please select at least one participant",
      TripDurationErrorMessage: "Please select at least one option",
    },
    Courses: {
      SelectYourCourses: "Select your courses",
      SelectYourCoursesPlaceholder: "Search courses...",
      CoursesText: "courses",
      CoursesSelected: "courses selected",
      SearchApiRoute: "/api/sitecore/tailoredexperience/searchcourses",
      ApiRoute: "/api/sitecore/tailoredexperience/getcourses",
      StepTitle: "Golf",
      Title: "Choose your courses",
      Description:
        "Prow scuttle parrel provost Sail o shrouds spirits boom mizzenmast yardarm. Pinnace holystone mizzenmast quarter crow's nest nipperkin grog yardarm hempen halter furl.",
      ShowWhatHappensNext: false,
    },
    Hotels: {
      SelectYourHotels: "Select your hotels",
      HotelsText: "hotels",
      HotelsSelected: "hotels selected",
      ApiRoute: "/api/sitecore/tailoredexperience/gethotels",
      StepTitle: "Hotels",
      Title: "Choose your hotels",
      Description:
        "Prow scuttle parrel provost Sail o shrouds spirits boom mizzenmast yardarm. Pinnace holystone mizzenmast quarter crow's nest nipperkin grog yardarm hempen halter furl.",
      ShowWhatHappensNext: false,
      NoResults: "No hotels available",
    },
    Tours: {
      AdditionalRequirements: "",
      AdditionalRequirementsPlaceholder: "",
      ToursText: "",
      ToursSelected: "",
      ApiRoute: "/api/sitecore/tailoredexperience/gettours",
      StepTitle: "Tours",
      Title: "Choose your tours",
      Description:
        "Prow scuttle parrel provost Sail o shrouds spirits boom mizzenmast yardarm. Pinnace holystone mizzenmast quarter crow's nest nipperkin grog yardarm hempen halter furl.",
      ShowWhatHappensNext: false,
      NoResults: "No tours available",
    },
    Transport: {
      TransportOptions: [
        {
          Title: "Yes, I require transport",
          Description: "lorem ipsum",
          ImgSrc: "",
        },
        {
          Title: "No, I do not require transport",
          Description:
            "Prow scuttle parrel provost Sail o shrouds spirits boom mizzenmast yardarm. Pinnace holystone mizzenmast quarter crow's nest nipperkin grog yardarm hempen halter furl.",
          ImgSrc: "",
        },
      ],
      StepTitle: "Your Transport",
      Title: "Transport",
      Description:
        "Prow scuttle parrel provost Sail o shrouds spirits boom mizzenmast yardarm. Pinnace holystone mizzenmast quarter crow's nest nipperkin grog yardarm hempen halter furl.",
      ShowWhatHappensNext: false,
    },
    Confirmation: {
      BackToHomeBtn: "Back to home",
      BackToHomeLink: "/",
      ImgSrc: "",
      StepTitle: "Confirmation",
      Title: "Thank you for your enquiry",
      Description:
        "A member of the Experience Team will respond within [XX hours / working days]. Please note our business hours are Monday to Friday - 09:00 to 17.30 (GMT).",
      ShowWhatHappensNext: false,
    },
    LocationSource: "",
    GoogleApiKey: "",
  },
  domain: "",
  locationsPath: "",
  filtersSlice: {
    selectedCountries: [],
    selectedRegions: [],
  },
  setSelectedRegions: () => {},
  setSelectedCountries: () => {},
  isFiltersDirty: false,
  isRegionsDirty: false,
  isSubmitLoading: false,
  submitError: "",
};

export const ExperienceBuilderContext = createContext<
  IExperienceBuilderContext | undefined
>(defaultContext);

type TExperienceBuilderContextProviderProps = {
  children: ReactNode;
  domain: string;
  locationsPath: string;
  labels: TExperienceBuilderLabels;
  sourceId: string;
};

export const ExperienceBuilderContextProvider: FC<
  TExperienceBuilderContextProviderProps
> = ({ children, domain, locationsPath, labels, sourceId }) => {
  const [navigationSlice, setNavigationSlice] = useState<TNavigationStateSlice>(
    defaultContext.navigationSlice
  );
  const [isFiltersDirty, setIsFiltersDirty] = useState(false);
  const [isRegionsDirty, setIsRegionsFiltersDirty] = useState(false);
  const [tripDetails, setTripDetails] = useState(defaultContext.tripDetails);
  const [toursDetails, setToursDetails] = useState(defaultContext.toursDetails);
  const [yourDetails, setYourDetails] = useState(defaultContext.yourDetails);
  const [filtersSlice, setFiltersSlice] = useState(defaultContext.filtersSlice);
  const [selectedCourses, setSelectedCourses] = useState<
    TExperienceBuilderCourse[]
  >([]);
  const [selectedHotels, setSelectedHotels] = useState<
    TExperienceBuilderHotel[]
  >([]);
  const [selectedTours, setSelectedTours] = useState<TExperienceBuilderTour[]>(
    []
  );
  const [selectedTransport, setSelectedTransport] =
    useState<TExperienceTransportOption>(labels.Transport.TransportOptions[0]);
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
  const [submitError, setSubmitError] = useState<string>("");

  const setActiveStep = useCallback((newStep: TSteps) => {
    setNavigationSlice((state) => ({ ...state, activeStep: newStep }));

    const componentContainer = document.getElementsByClassName(
      "o-tailored-experience"
    )?.[0];

    if (componentContainer) {
      componentContainer.scrollIntoView();
    }
  }, []);

  useEffect(() => {
    // sets overflow-x = visible on main DOM element in order to make sticky map work
    // happens only on the steps where the map is visible -> 2,3,4
    const mainElement = document.getElementsByTagName("main")?.[0];

    if (mainElement) {
      mainElement.style.overflowX = ["courses", "hotels", "tours"].includes(
        navigationSlice.activeStep
      )
        ? "visible"
        : "hidden";
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigationSlice.activeStep]);

  useEffect(() => {
    let componentIsVisible = false;
    if (navigationSlice.activeStep === "tripDetails") {
      componentIsVisible = labels.TripDetails.ShowWhatHappensNext;
    }
    if (navigationSlice.activeStep === "courses") {
      componentIsVisible = labels.Courses.ShowWhatHappensNext;
    }
    if (navigationSlice.activeStep === "hotels") {
      componentIsVisible = labels.Hotels.ShowWhatHappensNext;
    }
    if (navigationSlice.activeStep === "tours") {
      componentIsVisible = labels.Tours.ShowWhatHappensNext;
    }
    if (navigationSlice.activeStep === "transport") {
      componentIsVisible = labels.Transport.ShowWhatHappensNext;
    }
    if (navigationSlice.activeStep === "yourDetails") {
      componentIsVisible = labels.YourDetails.ShowWhatHappensNext;
    }
    if (navigationSlice.activeStep === "confirmation") {
      componentIsVisible = labels.Confirmation.ShowWhatHappensNext;
    }
    const mediaComponent = document.getElementsByClassName(
      "o-general-media__wrapper"
    )?.[0] as HTMLElement;

    if (mediaComponent) {
      mediaComponent.style.display = componentIsVisible ? "flex" : "none";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigationSlice.activeStep]);

  useEffect(() => {
    // sets the default transport option
    const preSelectedItem = labels.Transport.TransportOptions?.at(0);

    preSelectedItem && setSelectedTransport(preSelectedItem);
  }, [labels]);

  const setSelectedCountries = useCallback(
    (newCountries: TExperienceBuilderCountry[]) => {
      // reset selection values once user interacted with filters
      setSelectedCourses([]);
      setSelectedHotels([]);
      setSelectedTours([]);

      // mark that the user has interacted with country filters
      setIsFiltersDirty(true);

      const newCountryRegions = newCountries.flatMap(
        (country) => country.Regions
      );

      setFiltersSlice((state) => {
        const existingRegionIds = state.selectedRegions.map(
          (region) => region.Id
        );

        return {
          ...state,
          selectedCountries: newCountries,
          selectedRegions: newCountryRegions.filter((region) =>
            existingRegionIds.includes(region.Id)
          ),
        };
      });
    },
    []
  );

  const setSelectedRegions = useCallback(
    (newRegions: TExperienceBuilderRegion[]) => {
      // reset selection values once user interacted with filters
      setSelectedCourses([]);
      setSelectedHotels([]);
      setSelectedTours([]);

      // mark that the user has interacted with region filters
      setIsRegionsFiltersDirty(true);

      setFiltersSlice((state) => ({
        ...state,
        selectedRegions: newRegions,
      }));
    },
    []
  );

  const setActiveCountriesAndRegion = useCallback(
    (
      countries: TExperienceBuilderCountry[],
      regions: TExperienceBuilderRegion[]
    ) => {
      // reset selection values once user interacted with filters
      setSelectedCourses([]);
      setSelectedHotels([]);
      setSelectedTours([]);

      // mark that the user has interacted with country and region filters
      setIsFiltersDirty(true);
      setIsRegionsFiltersDirty(true);

      setSelectedCountries(countries);
      setSelectedRegions(regions);
    },
    [
      setSelectedRegions,
      setSelectedCountries,
      setIsFiltersDirty,
      setIsRegionsFiltersDirty,
      setSelectedCourses,
      setSelectedHotels,
      setSelectedTours,
    ]
  );

  const onYourDetailsSubmit = useCallback(
    (yourDetails: TYourDetails) => {
      const personalDetails: TExperiencePersonalData = {
        ArrivalDate: tripDetails.arrivalDate,
        CountryCode: yourDetails.countryCode,
        Email: yourDetails.email,
        FirstName: yourDetails.firstName,
        Title: (yourDetails.title?.label as string) ?? "",
        LastName: yourDetails.lastName,
        NoArrivalDateSpecified: tripDetails.isNotSpecificDate,
        NumberOfGolfers: tripDetails.noOfGolfers,
        NumberOfNonGolfers: tripDetails.noOfNonGolfers,
        PhoneNumber: yourDetails.phoneNumber,
        SubscribeToNewsletter: yourDetails.isSubscriber,
        TripDuration: (tripDetails.tripDuration?.label as string) ?? "",
        AdditionalRequirements: toursDetails.additionalRequirements,
        SelectedCourses: selectedCourses,
        SelectedHotels: selectedHotels,
        SelectedTours: selectedTours,
        TransportOption: selectedTransport,
      };

      setIsSubmitLoading(true);
      setSubmitError("");
      axios
        .request({
          method: "POST",
          url: domain + labels.YourDetails.ApiRoute,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          withCredentials: true,
          data: { personalData: personalDetails, datasourceId: sourceId },
        })
        .then((res) => {
          if (res.data.Success) {
            // if everything goes well
            setActiveStep("confirmation");
          } else {
            setSubmitError(labels.Common.ErrorMessage);
          }
          setIsSubmitLoading(false);
        })
        .catch(() => {
          setSubmitError(labels.Common.ErrorMessage);
          setIsSubmitLoading(false);
        });
    },
    [
      tripDetails,
      selectedCourses,
      selectedHotels,
      selectedTours,
      selectedTransport,
      toursDetails,
      domain,
      labels.YourDetails.ApiRoute,
      sourceId,
      setActiveStep,
      setSubmitError,
      labels.Common.ErrorMessage,
    ]
  );

  const contextValue: IExperienceBuilderContext = useMemo(
    () => ({
      navigationSlice,
      setActiveStep: setActiveStep,
      tripDetails,
      yourDetails,
      toursDetails,
      setYourDetails,
      setTripDetails,
      setToursDetails,
      domain,
      locationsPath,
      labels,
      filtersSlice,
      setSelectedRegions,
      setSelectedCountries,
      selectedCourses,
      setSelectedCourses,
      isFiltersDirty,
      isRegionsDirty,
      selectedHotels,
      setSelectedHotels,
      selectedTours,
      setSelectedTours,
      selectedTransport,
      setSelectedTransport,
      onYourDetailsSubmit,
      setActiveCountriesAndRegion,
      isSubmitLoading,
      submitError,
    }),
    [
      navigationSlice,
      setActiveStep,
      tripDetails,
      yourDetails,
      toursDetails,
      domain,
      locationsPath,
      labels,
      filtersSlice,
      setSelectedRegions,
      setSelectedCountries,
      selectedCourses,
      isFiltersDirty,
      isRegionsDirty,
      selectedHotels,
      selectedTours,
      selectedTransport,
      onYourDetailsSubmit,
      setActiveCountriesAndRegion,
      isSubmitLoading,
      submitError,
    ]
  );

  return (
    <ExperienceBuilderContext.Provider value={contextValue}>
      {children}
    </ExperienceBuilderContext.Provider>
  );
};

export const useExperienceBuilderContext = (): IExperienceBuilderContext => {
  const context = useContext(ExperienceBuilderContext);
  if (!context) {
    console.error(
      "useExperienceBuilderContext must be used within a ExperienceBuilderContextProvider"
    );

    return defaultContext;
  }
  return context;
};
