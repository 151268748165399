import { getDOMComponentBaseName, getDOMComponentDomain } from "helpers";
import { StyleProvider } from "components/StyleContext/StyleContext";
import { STYLES } from "../../constants";
import ExperienceBuilderPage from "./components/ExperienceBuilderPage";
import { ExperienceBuilderContextProvider } from "./context/ExperienceBuilderContext";
import { TExperienceBuilderLabels } from "./ExperienceBuilderEG.types";
import { BrowserRouter, Route, Routes } from "react-router-dom";

type TBlogProps = {
  rootElement: HTMLElement;
};

function ExperienceBuilderEG({ rootElement }: TBlogProps) {
  const domain = getDOMComponentDomain(rootElement) ?? "";
  const locationsPath =
    rootElement?.getAttribute("data-domain-path-locations") ?? "";
  const data = rootElement?.getAttribute("data-labels") ?? ({} as string);
  const basename = getDOMComponentBaseName(rootElement);
  const sourceId = rootElement?.getAttribute("data-sourceid") ?? "";

  if (!data || !domain || !locationsPath) {
    return <></>;
  }

  const parsedData = JSON.parse(data) as Partial<TExperienceBuilderLabels>;

  return (
    <StyleProvider style={STYLES.EG}>
      <ExperienceBuilderContextProvider
        domain={domain}
        locationsPath={locationsPath}
        labels={
          {
            ...parsedData,
            TripDetails: {
              ...parsedData.TripDetails,
            },
            YourDetails: {
              ...parsedData.YourDetails,
            },
            Hotels: {
              NoResults: "No hotels available",
              ...parsedData.Hotels,
            },
            Tours: {
              NoResults: "No tours available",
              ...parsedData.Tours,
            },
          } as TExperienceBuilderLabels
        }
        sourceId={sourceId}
      >
        <BrowserRouter basename={basename}>
          <Routes>
            <Route path="/?" element={<ExperienceBuilderPage />} />
          </Routes>
        </BrowserRouter>
      </ExperienceBuilderContextProvider>
    </StyleProvider>
  );
}

export default ExperienceBuilderEG;
